import React from "react"
import FacebookIcon from "../assets/facebook.svg"
import LinkedinIcon from "../assets/linkedin.svg"
import TwitterIcon from "../assets/twitter.svg"

const socialLinks = [
  {
    id: 1,
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/codewithbasit",
  },
  {
    id: 2,
    icon: <TwitterIcon />,
    link: "https://twitter.com/Basit_Miyanji",
  },
  {
    id: 3,
    icon: <LinkedinIcon />,
    link: "https://www.linkedin.com/in/abdulbasitprofile/",
  },
]

export { socialLinks }
