const tech = [
  {
    name: "React JS",
    id: 1,
  },
  {
    name: "Node.js",
    id: 2,
  },
  {
    name: "React Native",
    id: 3,
  },
  {
    name: "HTML & CSS",
    id: 4,
  },
]

const projects = [
  {
    title: "React JS",
    id: 1,
    project: [
      {
        id: 1,
        title: "Color Contrast Collection",
        image:
          "https://res.cloudinary.com/dyvkdwzcj/image/upload/v1645090814/color-contrast_n598kw.png",
        short_description:
          "Curated Background with Text colors contrast, help you to pick best contrast for your websites.",
        demo: "https://color-contrast-collection.netlify.app/",
      },
      {
        id: 2,
        title: "PWA Pomodoro Clock",
        image:
          "https://res.cloudinary.com/dyvkdwzcj/image/upload/v1598035107/pomodoro-clock_ctp2af.png",
        short_description:
          "A PWA Pomodoro Clock built with React and Typescript.",
        github: "https://github.com/AbdulBasit313/Pomodoro-Clock",
        demo: "https://pwa-pomodoro-clock.netlify.app/",
      },
      {
        id: 3,
        title: "Vocabulary Builder",
        image:
          "https://raw.githubusercontent.com/AbdulBasit313/React-Vocabulary-App/master/src/images/home.PNG",
        short_description:
          "A simple React App built with materialize-css to improve vocabulary.",
        github: "https://github.com/AbdulBasit313/React-Vocabulary-App",
        demo: "https://react-vocabulary-5b821.web.app/",
      },
    ],
  },
  {
    id: 2,
    title: "React Native",
    project: [
      {
        id: 1,
        title: "Bookshelf App",
        image:
          "https://cdn.pixabay.com/photo/2017/07/28/23/18/coming-soon-2550190_960_720.jpg",
        short_description:
          "Full-Stack app for bookworms, help them keep track of their books",
        github: "https://github.com/AbdulBasit313/React-Native-Bookshelf",
      },
      {
        id: 2,
        title: "Facebook Messenger",
        image:
          "https://cdn.pixabay.com/photo/2017/07/28/23/18/coming-soon-2550190_960_720.jpg",
        short_description: "Clone of facebook messenger ui",
        github: "https://github.com/AbdulBasit313/facebook-messenger-ui",
      },
      {
        id: 3,
        title: "Blogging App",
        image:
          "https://cdn.pixabay.com/photo/2017/07/28/23/18/coming-soon-2550190_960_720.jpg",
        short_description: "Blogging app created with Dev.to API",
        github: "#",
      },
    ],
  },
  {
    id: 3,
    title: "HTML & CSS",
    project: [
      {
        id: 1,
        title: "Product Landing Page",
        image:
          "https://res.cloudinary.com/dyvkdwzcj/image/upload/v1532884722/screely-1532884284309_pixfyx.png",
        short_description:
          "Responsive HTML & CSS landing page for a dummy company",
        codepen: "https://codepen.io/Basit600/full/KBqrZK/",
        tags: ["HTML", "CSS", "Landing Page"],
      },
      {
        id: 2,
        title: "Technical Documentation",
        image:
          "https://res.cloudinary.com/dyvkdwzcj/image/upload/v1532884733/screely-1532884429018_qi0tpx.png",
        short_description: "Responsive Technical Documentation for Reactjs",
        codepen: "https://codepen.io/Basit600/full/LBZoav/",
        tags: ["HTML", "CSS", "Documentations"],
      },
      {
        id: 3,
        title: "Periodic Table",
        image:
          "https://res.cloudinary.com/dyvkdwzcj/image/upload/v1594065587/periodic-table_bpqjqm.png",
        short_description:
          "Chemistry periodic table built with HTML using table",
        codepen: "https://codepen.io/Basit600/full/PoqrdgK",
        tags: ["HTML", "CSS", "SMIT"],
      },
      {
        id: 4,
        title: "Pure CSS Android Logo",
        image:
          "https://res.cloudinary.com/dyvkdwzcj/image/upload/v1594065595/css-android-logo_icupyj.png",
        short_description: "Android logo built with pure CSS",
        codepen: "https://codepen.io/Basit600/full/gjjbPO",
        tags: ["HTML", "CSS", "CSS Art"],
      },
    ],
  },
]

export { tech, projects }
