import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
import Articles from "../components/sections/Articles"
import Contact from "../components/sections/Contact"
import Intro from "../components/sections/Intro"
import Projects from "../components/sections/Projects"
import SEO from "../components/SEO"

const IndexPage = ({ data }) => {
  const projects = data.projects.nodes

  const bio = data.bio.siteMetadata

  return (
    <main>
      <Layout nav footer mb>
        <SEO />
        <Intro bio={bio} />
        <Projects projectsData={projects} />
        <Articles />
        <Contact />
      </Layout>
    </main>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query projectsQuery {
    bio: site {
      siteMetadata {
        author {
          name
          intro {
            text
            subText
          }
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { in: true } } }
    ) {
      nodes {
        id
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          cover
          demo
          github
          codepen
          short_description
          stack
          title
          published
        }
      }
    }
  }
`
