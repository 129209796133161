import React from "react"
import styled from "styled-components"
// import Star from '../../../assets/icons/star.svg'
import { device } from "../../styles/BreakPoints"

const ProjectFilterStyle = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 40px;
  /* @media ${device.laptopL} {
    margin-bottom: 30px;
  } */
  @media ${device.tablet} {
    text-align: unset;
    margin-bottom: 25px;
  }
  ul {
    text-decoration: none;
    @media ${device.tablet} {
      /* padding: 0 30px; */
      display: flex;
      justify-content: space-evenly;
    }
    li {
      display: inline-block;
      /* color: ${({ theme: { colors } }) => colors.grayText}; */
      color: #3c2b8a;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      margin-right: 80px;
      @media ${device.tablet} {
        font-size: 12px;
        margin-right: unset;
      }
    }
    .selected {
      /* background: ${({ theme: { colors } }) => colors.primary}; */
      background: rgba(177, 175, 245, 0.3);
    }
    .box {
      border-radius: 5px;
      padding: 7px 14px;
      @media ${device.tablet} {
        padding: 5px 11px;
      }
    }
    .star-icon {
      margin-right: 6px;
      @media ${device.tablet} {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }
    .filter-text {
      @media ${device.tablet} {
        margin-left: 4px;
      }
    }
  }
`

function ProjectFilter({ projectFilter, activeTab, onChangeTab }) {
  return (
    <ProjectFilterStyle>
      <ul>
        {projectFilter.map(({ id, name }) => (
          <li
            key={id}
            className={name === activeTab ? "box selected" : "box"}
            // onClick={() => onChangeFilter(id)}
            onClick={() => onChangeTab(name)}
          >
            {/* {id === 1 && <Star className="star-icon" />} */}
            <span className="filter-text">{name}</span>
          </li>
        ))}
      </ul>
    </ProjectFilterStyle>
  )
}

export default ProjectFilter
