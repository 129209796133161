import React, { useState, useEffect } from "react"
import axios from "axios"
import styled from "styled-components"
import { Container, SectionWrapper } from "../../../styles/Global"
import SectionTitle from "../../SectionTitle"
import { device } from "../../../styles/BreakPoints"

const MainArticleContainer = styled.div`
  ${SectionWrapper}
  background: #F6F9FC;
`

const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 50px;
  margin-top: 40px;
  @media ${device.laptopL} {
    grid-column-gap: 30px;
    grid-row-gap: 36px;
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    margin-top: 20px;
  }
`

const ArticleCard = styled.div`
  background: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 10px;
  .card-header {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .card-body {
    padding: 12px 20px 10px 20px;
    @media ${device.laptop} {
      padding: 10px 14px;
    }
    @media ${device.mobileL} {
      padding: 10px 10px;
    }
    a {
      text-decoration: none;
      h4 {
        color: #261064;
        font-size: 19px;
        font-weight: 600;
        @media ${device.tablet} {
          font-size: 17px;
        }
        @media ${device.mobileL} {
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }
    p {
      font-size: 13px;
      font-weight: 300;
      color: #261064;
      margin-top: 12px;
      line-height: 1.4;
      @media ${device.tablet} {
        font-size: 12px;
      }
      @media ${device.mobileL} {
        margin-top: 7px;
        font-size: 12px;
      }
    }
    .writer {
      display: flex;
      align-items: center;
      margin-top: 12px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        @media ${device.tablet} {
          width: 30px;
          height: 30px;
        }
      }
      .writer-info {
        @media ${device.tablet} {
          display: flex;
          flex-direction: column;
        }
        h5 {
          @media ${device.tablet} {
            font-size: 11px;
          }
        }
        .date {
          @media ${device.tablet} {
            font-size: 10px;
            line-height: 1;
            display: inline-block;
            margin-top: 3px;
          }
        }
      }
    }
  }
`

const Articles = () => {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    let url = "https://dev.to/api/articles?username=abdulbasit313"
    axios.get(url).then((res) => {
      setArticles(res.data)
    })
  }, [])

  const renderArticles = () => {
    return articles.map((item) => {
      const date = new Date(item.published_at)
      const publishedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`

      return (
        <ArticleCard className="article-card" key={item.id}>
          <div className="card-header">
            <img src={item.cover_image} alt="cover-img" />
          </div>
          <div className="card-body">
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <h4>{item.title}</h4>
            </a>
            <p>{item.description}</p>
            <div className="writer">
              <img src={item.user.profile_image} alt="profile img" />
              <div className="writer-info">
                <h5>{item.user.name}</h5>
                <small className="date">{publishedDate}</small>
              </div>
            </div>
          </div>
        </ArticleCard>
      )
    })
  }

  if (articles.length === 0) {
    return <h2>Trouble connecting to internet</h2>
  }

  return (
    <MainArticleContainer id="articles">
      <Container>
        <SectionTitle title="Articles" subtitle="" />
        <ArticleContainer>{renderArticles()}</ArticleContainer>
      </Container>
    </MainArticleContainer>
  )
}

export default Articles
