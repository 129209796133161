import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { socialLinks } from "../../../site-data/socialLinks"
import { device } from "../../../styles/BreakPoints"
import { SectionWrapper } from "../../../styles/Global"

const ContactStyle = styled.div`
  width: 100%;
  max-width: 1200px; // remember this container width
  padding: 0 15px;
  display: flex;
  background-color: white;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  ${SectionWrapper}
  @media ${device.mobileL} {
    display: block;
  }
`

const ContactTextContainer = styled.div`
  h5 {
    font-size: 50px;
    font-weight: bold;
    color: #220f5f;
    @media ${device.tablet} {
      font-size: 36px;
    }
    @media ${device.mobileL} {
      font-size: 30px;
    }
  }
  p {
    font-size: 24px;
    color: #220f5f;
    font-weight: 300;
    @media ${device.tablet} {
      font-size: 18px;
    }
    @media ${device.mobileL} {
      font-size: 16px;
    }
  }
`

const SocialIcons = styled.div`
  display: flex;
  gap: 11px;
  margin-top: 30px;
  @media ${device.mobileL} {
    margin-top: 16px;
  }
`
const SocialIcon = styled.a`
  border: 1px solid #34126a;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    border: 1px solid #ffd7d5;
    background-color: #ffd7d5;
  }
  @media ${device.mobileL} {
    width: 32px;
    height: 32px;
  }
`

const ContactSvg = styled.div`
  @media ${device.laptopL} {
    width: 420px;
  }
  @media ${device.laptop} {
    width: 370px;
  }
  @media ${device.tablet} {
    width: 310px;
  }
  @media ${device.mobileL} {
    margin: 0 auto;
    margin-top: 30px;
    width: 250px;
  }
`

function Contact() {
  return (
    <ContactStyle id="contact">
      <ContactTextContainer>
        <h5>Want to Chat ?</h5>
        <p>Or Have Something to Discuss</p>
        <SocialIcons>
          {socialLinks.map(({ id, icon, link }) => (
            <SocialIcon key={id} href={link} target="_blank">
              {icon}
            </SocialIcon>
          ))}
        </SocialIcons>
      </ContactTextContainer>
      <ContactSvg>
        <StaticImage
          className="contact-image"
          src={"../../../assets/contact-svg-1.png"}
          alt="Contact SVG"
        />
      </ContactSvg>
    </ContactStyle>
  )
}

export default Contact
