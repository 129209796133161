import React, { useState } from "react"
import styled from "styled-components"
import { tech } from "../../../site-data/projects"
import { device } from "../../../styles/BreakPoints"
import { Container, SectionWrapper } from "../../../styles/Global"
import ProjectCard from "../../ProjectCard"
import ProjectFilter from "../../ProjectFilter"
import SectionTitle from "../../SectionTitle"

const ProjectMainContainer = styled.div`
  ${SectionWrapper}
  background: #F0FAF9;
`

const ProjectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 35px;
  @media ${device.laptopL} {
    grid-gap: 30px;
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px;
    margin-top: unset;
  }
`

const Projects = ({ projectsData }) => {
  const [activeTab, setActiveTab] = useState("React JS")

  const onChangeTab = (tab) => {
    setActiveTab(tab)
  }

  return (
    <ProjectMainContainer id="projects">
      <Container>
        <SectionTitle title="Projects" subtitle="" />
        <ProjectFilter
          projectFilter={tech}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
        />
        <ProjectContainer>
          {projectsData.map((detail) => {
            if (detail.frontmatter.stack === activeTab)
              return <ProjectCard detail={detail} key={detail.id} />
          })}
        </ProjectContainer>
      </Container>
    </ProjectMainContainer>
  )
}

export default Projects
