import React from "react"
import styled from "styled-components"
import { device } from "../../styles/BreakPoints"

const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 14px #7e7e7e63;
  border-radius: 5px;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptopL} {
    padding: 12px 21px;
  }
  @media ${device.tablet} {
    padding: 12px 13px;
  }
  @media ${device.mobileL} {
    padding: 8px 8px;
  }
`

const ProjectImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 210px;
  @media ${device.laptopL} {
    height: 180px;
  }
  @media ${device.tablet} {
    height: 140px;
  }
  @media ${device.mobileL} {
    height: 100px;
  }
`

const ProjectTitle = styled.h5`
  color: #481a72;
  font-size: 23px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 10px;
  @media ${device.tablet} {
    font-size: 19px;
    margin-top: 6px;
    margin-bottom: 6px;
    line-height: 1;
  }
  @media ${device.mobileL} {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    line-height: 1.2;
  }
`

const BtnContainer = styled.p`
  display: flex;
  gap: 17px;
  margin-top: 10px;
  @media ${device.mobileL} {
  }
`

const ProjectBtn = styled.a`
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 18px;
  color: #4c1e78;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.mobileL} {
    font-size: 13px;
  }
`

const ProjectDesc = styled.p`
  color: #3c2b8a;
  font-size: 15px;
  font-weight: 300;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 1.4;
  }
`

function ProjectCard({ detail }) {
  const { frontmatter } = detail
  const { title, cover, short_description, github, demo, codepen } =
    frontmatter || {}

  return (
    <CardContainer>
      <ProjectImg src={cover} alt="" />
      <div>
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectDesc>{short_description}</ProjectDesc>
        <BtnContainer>
          {github && (
            <ProjectBtn href={github} target="_blank" rel="noopener noreferrer">
              Github
            </ProjectBtn>
          )}
          {demo && (
            <ProjectBtn href={demo} target="_blank" rel="noopener noreferrer">
              Demo
            </ProjectBtn>
          )}
          {codepen && (
            <ProjectBtn
              href={codepen}
              target="_blank"
              rel="noopener noreferrer"
            >
              Codepen
            </ProjectBtn>
          )}
        </BtnContainer>
      </div>
    </CardContainer>
  )
}

export default ProjectCard
