import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Web from "../../../assets/intro-svg.svg"
import { device } from "../../../styles/BreakPoints"

const IntroStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background: transparent
    linear-gradient(
      180deg,
      rgba(177, 175, 245, 0.37),
      rgba(255, 255, 255, 0.37)
    );
  height: calc(100vh - 85px);
  @media ${device.laptopL} {
    height: calc(100vh - 50px);
  }
`

const IntroWrapper = styled.div`
  width: 100%;
  max-width: 1460px; // remember this container width
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media ${device.laptop} {
    padding: 0 15px;
  }
  @media ${device.tablet} {
    flex-direction: column;
    gap: 30px;
  }
`

const IntroTextContainer = styled.div``

const IntroTitle = styled.h1`
  background: transparent linear-gradient(271deg, #ff9f3d 0%, #ef5338 100%) 0%
    0% no-repeat padding-box;
  font-weight: 800;
  font-size: 45px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 24px #7e7e7e63;
  margin-bottom: 8px;
  @media ${device.laptopL} {
    font-size: 40px;
  }
  @media ${device.laptop} {
    font-size: 36px;
  }
  @media ${device.tablet} {
    font-size: 36px;
  }
  @media ${device.mobileL} {
    font-size: 34px;
  }
`

const IntroSubtitle = styled.h2`
  font-size: 22px;
  letter-spacing: 2px;
  color: #3e2d8e;
  @media ${device.laptopL} {
    font-size: 19px;
  }
  @media ${device.laptop} {
    font-size: 17x;
  }
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 1.4;
  }
`

const SkillsList = styled.div`
  font-size: 19px;
  color: #3e2d8e;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  max-width: 450px;
  li {
    border: 1px solid #fffaf3;
    padding: 5px;
    border-radius: 5px;
    margin-right: 4px;
    margin-bottom: 5px;
  }
  @media ${device.laptopL} {
    font-size: 17px;
  }
  @media ${device.laptop} {
    font-size: 16x;
  }
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 1.4;
  }
`

const JourneyBtn = styled.button`
  background: transparent linear-gradient(275deg, #481a72 0%, #57298b 100%);
  box-shadow: 0px 10px 24px #7e7e7e63;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 40px;
  border-radius: 4px;
  @media ${device.laptopL} {
    padding: 6px 12px;
    font-size: 18px;
  }
  @media ${device.laptop} {
    font-size: 16x;
  }
  @media ${device.mobileL} {
    padding: 6px 12px;
    font-size: 16px;
  }
`

const SVGContainer = styled.div`
  animation: SVGmove infinite 3s linear;
  @media ${device.laptopL} {
    svg {
      width: 600px;
    }
  }
  @media ${device.laptop} {
    svg {
      width: 470px;
    }
  }
  @media ${device.tabletL} {
    svg {
      width: 400px;
    }
  }
  @media ${device.tabletL} {
    svg {
      width: 400px;
      height: 250px;
    }
  }
  @media ${device.mobileL} {
    svg {
      height: 240px;
      width: 340px;
    }
  }
  /* width: 100%; */

  @keyframes SVGmove {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(16px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

function Intro({ bio }) {
  const {
    author: {
      name,
      intro: { text, subText },
    },
  } = bio
  return (
    <IntroStyle id="home">
      <IntroWrapper>
        <IntroTextContainer>
          <IntroTitle>I AM {name}</IntroTitle>
          <IntroSubtitle>
            {text}
            <br /> {subText}
          </IntroSubtitle>
          <SkillsList>
            <li># ReactJS</li>
            <li># Node.js</li>
            <li># JavaScript (ES6)</li>
            <li># TypeScript</li>
            <li># Styled Components</li>
            <li># HTML5</li>
            <li># CSS3</li>
          </SkillsList>
          <Link to="/timeline">
            <JourneyBtn>See My Journey</JourneyBtn>
          </Link>
        </IntroTextContainer>
        <SVGContainer>
          <Web />
        </SVGContainer>
      </IntroWrapper>
    </IntroStyle>
  )
}

export default Intro
