import React from "react"
import styled from "styled-components"
import { device } from "../../styles/BreakPoints"

const TitleStyle = styled.h2`
  color: #33146d;
  font-size: 42px;
  font-weight: 800;
  @media ${device.laptopL} {
    font-size: 34px;
  }
  @media ${device.mobileL} {
    font-size: 26px;
  }
`

const Subtitle = styled.p`
  color: #3c2b8a;
  font-size: 18px;
  font-weight: bold;
`

function SectionTitle({ title, subtitle }) {
  return (
    <>
      <TitleStyle>{title.toUpperCase()}</TitleStyle>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </>
  )
}

export default SectionTitle
